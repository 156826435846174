<template>
  <div class="level" :class="additionalClass">
    <div class="level-left">
      <div :class="titleClass">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="level-right">
      <a v-if="showAdd" @click="addFunction" class="has-icon">
        <span class="icon">
          <font-awesome-icon :icon="['fa', 'plus']" />
        </span>
        <span>{{ addLabel }}</span>
      </a>

      <a v-if="showEdit" @click="editFunction" class="has-icon has-margin-left">
        <span class="icon">
          <font-awesome-icon :icon="['fa', 'edit']" />
        </span>
        <span>{{ editLabel }}</span>
      </a>

      <a
        v-if="showDelete"
        @click="deleteFunction"
        class="has-icon has-margin-left"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fa', 'trash']" />
        </span>
        <span>{{ deleteLabel }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-header',

  props: {
    addFunction: {
      type: Function,
      default: () => {},
    },

    editFunction: {
      type: Function,
      default: () => {},
    },

    deleteFunction: {
      type: Function,
      default: () => {},
    },

    addLabel: {
      type: String,
      default: 'Add',
    },

    editLabel: {
      type: String,
      default: 'Edit',
    },

    deleteLabel: {
      type: String,
      default: 'Delete',
    },

    additionalClass: {
      type: String,
      default: '',
    },

    titleClass: {
      type: String,
      default: 'title is-3',
    },

    showAdd: {
      type: Boolean,
      default: false,
    },

    showEdit: {
      type: Boolean,
      default: false,
    },

    showDelete: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
